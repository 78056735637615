import Vue from 'vue';
import VueRouter from 'vue-router';
import About from '../views/About.vue';
import Projects from '../views/Projects.vue';
import Skills from '../views/Skills.vue';
import Experiences from '../views/Experiences.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'About',
    component: About,
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
  },
  {
    path: '/skills',
    name: 'Skills',
    component: Skills,
  },
  {
    path: '/experiences',
    name: 'Experiences',
    component: Experiences,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  next();
});
export default router;
