<template>
  <div class="skills page">
    <!-- <h2>Skills</h2> -->
    <div class="skill"
      v-for="(s, i) in skills" :key="i">
      <h2>{{s.name}}</h2>
      <p class="comment">{{s.comment}}</p>

    </div>
  </div>
</template>

<script>
import { skills } from '@/data/skills.json';

export default {
  data() {
    return {
      skills,
    };
  },
};
</script>
