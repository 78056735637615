<template>
  <div class="projects page">
    <!-- <h2>Projets</h2> -->
    <div class="disclaimer">
      <p>Ces projets n'ont pour seule vocation que d'explorer certains concepts,
        souvent par curiosité ou par défi technique.<br>
        Cependant, certains comme QxPrice ou Qwitix ont dépassé ce stade
        et ont été exploités en production.
      </p>
    </div>
    <div class="project"
      v-for="(p, i) in projects" :key="i"
      @click="toggle(i)" :class="{toggled: isToggled(i)}"
      >
      <div class="sticky head">
        <h2><span>{{p.title}}</span></h2>
        <span class="period">{{p.period}}</span>
      </div>
        <p class="summary">{{p.summary}}</p>
        <p class="description"
          v-for="(desc, i) in p.description" :key="i" >
          {{desc}}
        </p>
        <div class="tags">
          <span class="tag"
            v-for="(tag, i) in p.tags" :key="i">{{tag}}
          </span>
        </div>
    </div>
  </div>
</template>

<script>
import { projects } from '@/data/projects.json';

export default {
  data() {
    return {
      projects,
      toggled: 0,
    };
  },
  methods: {
    toggle(i) {
      this.toggled = i;
    },
    isToggled(i) {
      return this.toggled === i;
    },
  },
};
</script>
<style lang="scss" scoped>
.disclaimer {
  font-style: italic;
  font-size: 1.1em;
}

.project {
  margin-bottom: 2em;
}
.head {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  h2 {
    flex:1;
    margin: 0;
  }
  .period {
    flex:1
  }
}
.summary {
  font-style: italic;
  font-weight: 500;
}
.tags {
  text-align: right;
}
</style>
