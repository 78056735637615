<template>
  <div id="app" :class="{entering}">
    <h1><b>Brice</b> Dauzats</h1>
    <div id="nav">
      <router-link to="/">A propos</router-link>
      <router-link to="/projects">Projets</router-link>
      <router-link to="/experiences">Expériences</router-link>
      <!-- <router-link to="/skills">Compétences</router-link> -->
    </div>
    <transition
        name="fade"
        mode="out-in"
      >
      <router-view/>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      entering: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.entering = false;
    }, 500);
  },
};
</script>
<style lang="scss">
#app {
  transition: opacity 1s ease;
  h1 {
    transition: word-spacing .7s ease;
  }
}
.entering {
  opacity: 0;
  h1 {
    word-spacing: .7em;
  }
}
#nav {
  background-color: #fff;
  position: sticky;
  top:0;
  z-index: 100;
  box-shadow: 0 0 10px 10px #fff;
  margin-bottom: 5em;
  a {
    display: inline-block;
    font-weight: 400;
    color: #2c3e50;
    padding: 1em;
    text-decoration: none;
    position: relative;

    &.router-link-exact-active {
      color: #65c9ff;
      font-weight: 500;
      &::after {
        opacity: 1;
        transition: all ease .1s;
      }
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: #65c9ff;
      opacity: 0.2;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease,
   transform 0.2s ease;
  transform: translateY(0px);

  h2 {
    transition: transform 0.2s ease;
    transform: translateY(0px);
    letter-spacing: 0em;
    &::before {
      transition: transform 0.3s ease;
      transform: translateY(0px)  rotateZ(0);
    }
  }
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: translateY(-15px);
  h2 {
    transform: translateY(20px);
    &::before {
      transform: translateX(-20px) rotateZ(-0deg);
    }
  }
}
.fade-leave-active {
  h2 {
    transform: translateY(-10px);
    &::before {
      transform: translateX(20px) rotateZ(0deg);
    }
  }
}

</style>
