<template>
  <div class="about page">

    <div class="content">
      <h2><span>Qui suis-je ?</span></h2>
      <div class="me">
        <img class="avatar" src="@/assets/avatar.svg">
        <div>
          <p><strong>Je suis développeur et architecte logiciel.</strong></p>
          <p>Autodidacte, sérieux, passionné, dynamique, autonome, curieux et créatif.</p>
          <!-- <p>J'ai 15 ans d’expérience dans diverses sociétés au sein desquelles
            j'ai réalisé de très nombreux projets. De la PME locale jusqu’à la banque,
            en passant pas les startup innovantes,
            j’ai eu la chance de travailler sur une grande variété de langages et technologies.
          </p> -->
          <p>
            Après 15 ans d'expérience sur de très nombreuses technologies,
            architectures et langages,<br>
            j'ai acquis et construit un savoir faire unique
            alliant vision produit, UX et architecture logicielle.
          </p>
          <p>
            Une vision parfois disruptive couplée aux nouvelles technologies cloud AWS serverless
            me permettent de proposer des architectures scalables,
            résilientes, fiables et peu coûteuses.
          </p>
          <!-- <p>J'ai acquis depuis quelques années une grande
            expertise sur les solutions cloud serverless de AWS.</p> -->
          <p>Aujourd’hui, je mets mes compétences à votre service via ma société <a href="https://www.opcode.fr/">opcode</a>.</p>
        </div>
      </div>
      <h2><span>Philosophie</span></h2>

      <!-- <p>Depuis des années j'essaie de tendre vers un modèle,
        où des concepts simples peuvent résourdre des problème complexes.</p> -->

      <q>Un problème est un ensemble d'autres problèmes de complexité moindre</q>

      <p>Cette citation récursive me permet d'envisager que la réussite d'un projet
        ne tient qu'à notre capacité et notre abnégation à résoudre les problèmes,
        et que <b>la solution à tout problème est un ensemble de <u>solutions simples</u></b>.</p>

      <p>Depuis des années, j'essaie de tendre vers ce modèle,
        dans lequel des <b>concepts simples</b>, bien assemblées,
        peuvent résourdre des problème complexes.</p>

      <p>
        Il résulte de cette philosophie :
        <ul>
          <li><b>Maintenabilité :</b>
            <p>Un système plus simple ⇢ plus facile à maintenir et à faire évoluer</p>

          </li>
          <li><b>Sécurité :</b>
             <p>Moins de dépendances tierces + un code réduit ⇢ une surface d'attaque diminuée
             et une meilleure auditabilité
             </p>
          </li>
          <li><b>Stabilité :</b>
             <p>Moins de lignes de code ⇢ moins de complexité ⇢ moins de bugs</p>
          </li>
          <li><b>Performance :</b>
             <p>Moins de complexité ⇢ performance.
             </p>
          </li>
          <li><b>Coûts :</b>
             <p>Moins de code ⇢ les coûts de développements sont moindres<br>
             Des technologies mieux adaptées plus efficientes ⇢ coût d'exploitation réduit
             </p>
          </li>
        </ul>
      </p>

      <h2><span>Me contacter</span></h2>
      <ul class="contact">
        <li><img src="@/assets/at.svg"><a :href="'mailto:'+email">{{email}}</a></li>
        <li><img src="@/assets/cellphone.svg"><a :href="'tel:'+phoneLink">{{phone}}</a></li>
        <li><img src="@/assets/linkedin.svg">
          <a href="https://www.linkedin.com/in/bricedauzats"
          target="_blank">bricedauzats</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      h: false,
    };
  },
  computed: {
    email() {
      return this.h ? atob('YnJpY2VAb3Bjb2RlLmZy') : '';
    },
    phone() {
      return (this.h ? atob('MDYgODcgMTUgMDMgMjM=') : '');
    },
    phoneLink() {
      return this.phone
        .replace(/ /g, '')
        .replace(/^0/, '+33');
    },
  },
  mounted() {
    setTimeout(() => {
      this.h = true;
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.me {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  &>img {
    display: block;
    margin-bottom: 2em;
  }
  &>div {
    flex-grow: 1;
    width: 500px;
  }
}

li>p {
  margin-top: 0.1em;
}

.contact {
  list-style: none;
  font-size: 1.1em;
  li {
    margin: .3em;
    a {
      text-decoration: none;
      color: #222;
    }
    a:hover {
      text-decoration: underline;
    }
  }
  li img{
    vertical-align: middle;
    margin: 0.2em;
  }
}
</style>
