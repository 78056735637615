<template>
  <div class="experiences page">
    <!-- <h2>Experiences</h2> -->
    <div class="experience"
      v-for="(ex, i) in experiences" :key="i"
      >
      <div class="experience-head sticky">
        <h2><span>{{ex.company}}</span></h2>
        <div class="experience-subhead">
          <div class="position">
            {{ex.position}}
          </div>
          <div class="period">
            <span class="from">{{ex.period[0]}}</span>
            <span class="to">{{ex.period[1]}}</span>
          </div>
        </div>
      </div>
      <div class="summary">{{ex.summary}}</div>
      <div class="projects">
        <div class="project"
          v-for="(p, i) in ex.projects.filter(p=>!p.hidden)" :key="i"
          @click="toggle(p)" :class="{toggled: isToggled(p)}">
          <h3>{{p.title}}</h3>
          <p class="summary">{{p.summary}}</p>
          <div class="details">
            <h5 v-if="p.context">Contexte :</h5>
            <p class="context" v-if="p.context">{{p.context}}</p>
            <h5>Description :</h5>
            <p class="description"
              v-for="(desc, i) in p.description" :key="i" >
              {{desc}}
            </p>
          </div>
          <div class="tags">
            <span class="tag"
              v-for="(tag, i) in p.tags" :key="i">{{tag}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { experiences } from '@/data/experiences.json';

export default {
  data() {
    return {
      experiences,
      toggled: null,
    };
  },
  methods: {
    toggle(i) {
      this.toggled = i;
    },
    isToggled(i) {
      return this.toggled === i;
    },
  },
};
</script>
<style lang="scss" scoped>

.experience {
  margin-bottom: 5em;
  position: relative;
  >.summary {
    font-style: italic;
  }
}
h2 {
    margin: 0;
}
.experience-subhead {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  h2, div {
    margin-right: 1em;
    &:last-child {
      margin-right: 0;
    }
  }

}

.position {
  flex-grow: 1;
  font-weight: 400;
  font-size: 1.4em;
}
.period {
  flex-grow: 1;
}
.project {
  // position: relative;

  .details {
    display: block;
    max-height: 0;
    overflow: hidden;
    border-left: 1px solid #65c9ff;
    padding-left: 1em;
    margin-left: 1em;
    transition: all ease .5s;
  }
  .tags {
    text-align: right;
  }
  &.toggled .details {
    max-height: 1000px;
  }
}

.project:not(.toggled) {
  cursor: pointer;
  &::before{
    content: '>>';
    position: absolute;
    color: #65c9ff;
    right: -15px;
    letter-spacing: 1em;
    font-weight: bold;
    backdrop-filter: blur(2px);
    padding: 0.6em 1em;
    background-color: rgba(#fff, .3);
    opacity: 0;
    transition: opacity .2s ease,
      right .2s ease,
      letter-spacing .2s ease;
  }
  &:hover::before{
    opacity: 1;
    right: 0;
    letter-spacing: 0;
  }
}

@media all and (max-width: 600px) {
  .position {
    font-size: 1.3em;
  }
}
</style>
